/*------ Chat ------*/

.chat {
	li {
		padding: 15px;
		overflow: hidden;
		display: flex;
	}
	.avatar {
		width: 40px;
		height: 40px;
		position: relative;
		display: block;
		z-index: 2;
		border-radius: 100%;
		-webkit-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		background-color: $white-9;
		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			background-color: $white-9;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}
	}
	.day {
		position: relative;
		display: block;
		text-align: center;
		color: silver;
		height: 20px;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		line-height: 38px;
		margin-top: 5px;
		margin-bottom: 20px;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
}

.other {
	.msg {
		order: 1;
		border: 1px solid #d8dde6;
		border-top-left-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
	&:before {
		content: "";
		position: relative;
		top: 0;
		right: 0;
		left: 50px;
		width: 0;
		height: 0;
		border: 5px solid #d8dde6;
		border-left-color: transparent;
		border-bottom-color: transparent;
	}
}

.self {
	justify-content: flex-end;
	align-items: flex-end;
	.msg {
		order: 1;
		border: 1px solid #d8dde6;
		border-bottom-right-radius: 0;
		box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
	}
	.avatar {
		order: 2;
		&:after {
			content: "";
			position: relative;
			display: inline-block;
			bottom: -14px;
			right: 26px;
			width: 0;
			height: 0;
			border: 5px solid #d8dde6;
			border-right-color: transparent;
			border-top-color: transparent;
			box-shadow: 0 0 0 1px rgba(243, 247, 253, 0.12), 0 8px 16px 0 rgba(243, 247, 253, 0.24);
		}
	}
}

.msg {
	background: $white;
	min-width: 50px;
	margin: 0 10px;
	padding: 10px;
	border-radius: 2px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07);
	p {
		font-size: 13px;
		margin: 0 0 .2rem 0;
		color: #313148;
	}
	img {
		position: relative;
		display: block;
		width: 450px;
		border-radius: 5px;
		box-shadow: 0 0 3px #eee;
		transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	time {
		font-size: .7rem;
		color: #a7b4c9;
		margin-top: 3px;
		float: right;
		cursor: default;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		&:before {
			content: "\f017";
			color: #a7b4c9;
			font-family: FontAwesome;
			display: inline-block;
			margin-right: 4px;
		}
	}
}

@media screen and (max-width: 800px) {
	.msg img {
		width: 300px;
	}
}

@media screen and (max-width: 550px) {
	.msg img {
		width: 200px;
	}
}

.social-title {
	text-transform: uppercase;
	letter-spacing: 1px;
	text-shadow: none;
	color: $white !important;
}

.socials {
	display: flex;
	li {
		margin: 0 12px;
		margin-right: 15px;
	}
}

.text-shadow1 {
	text-shadow: 0 10px 10px rgba(39, 29, 96, 0.4);
}

.count {
	font-size: 37px;
	margin-top: 10px;
	margin-bottom: 10px;
}


/*---- Chat -----*/

.chat {
	outline: 0;
	margin: 0;
	padding: 0;
	background: 0 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}

.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-top: 1rem;
	}
}

.chat-message {
	position: relative;
	display: inline-block;
	background-color: $blue;
	color: $white;
	font-size: .875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-bottom: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		right: -5px;
		top: 7px;
		border-bottom: 6px solid transparent;
		border-left: 6px solid $blue;
		border-top: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-bottom: 1em;
	}
}

.chat-line-friend {
	-ms-flex-direction: row;
	flex-direction: row;
	+.chat-line-friend {
		margin-top: -.5rem;
		.chat-author {
			visibility: hidden;
		}
		.chat-message:after {
			display: none;
		}
	}
	.chat-message {
		background-color: $background;
		color: #3d4e67;
		margin-left: .5rem;
		margin-right: 2.5rem;
		&:after {
			right: auto;
			left: -5px;
			border-left-width: 0;
			border-right: 5px solid $background;
		}
	}
}

.example {
	padding: 1.5rem;
	border: 1px solid #d8dde6;
	border-radius: 3px 3px 0 0;
	font-size: .9375rem;
}

.example-bg {
	background: $background;
}

.example+.highlight {
	border-top: none;
	margin-top: 0;
	border-radius: 0 0 3px 3px;
}

.highlight {
	margin: 1rem 0 2rem;
	border: 1px solid #d8dde6;
	border-radius: 3px;
	font-size: .9375rem;
	max-height: 40rem;
	overflow: auto;
	background: $background;
	background: rgba(255, 215, 0, 0.115);
	pre {
		margin-bottom: 0;
		background-color: rgba(5, 117, 230, 0.1);
	}
}

.example-column {
	margin: 0 auto;
	>.card:last-of-type {
		margin-bottom: 0;
	}
}

.example-column-1 {
	max-width: 20rem;
}

.example-column-2 {
	max-width: 40rem;
}