.select2-lg {
	.select2-container .select2-selection--single {
		height: 2.875rem !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 45px !important;
	}
}

.select2-sm {
	.select2-container .select2-selection--single {
		height: 1.775rem !important;
	}
	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 27px !important;
		}
		.select2-selection__arrow b {
			top: 80% !important;
		}
	}
}

.select2-container--default {
	.select2-selection--single {
		background-color: $white;
		border: 1px solid #d8dde6 !important;
		border-radius: 4px;
	}
	&.select2-container--focus .select2-selection--multiple {
		color: #3d4e67;
		background-color: $white;
		border-color: #4c8aec;
		outline: 0;
	}
	.select2-selection--multiple {
		background-color: $white;
		border: 1px solid #d8dde6 !important;
		border-radius: 4px;
		cursor: text;
	}
	.select2-search--dropdown .select2-search__field {
		border: 1px solid #d8dde6 !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $background !important;
		border: 1px solid $background !important;
	}
	.select2-selection--single .select2-selection__rendered {
		color: $color !important;
		line-height: 39px !important;
	}
}

@media (max-width: 992px) {
	.select2-container {
		width: 100% !important;
	}
	.select2.select2-container.select2-container--default.select2-container--focus {
		width: 100% !important;
		overflow: hidden;
	}
}

.select2-container .select2-selection--single {
	height: 2.375rem;
	height: 100% !important;
}

.select2.select2-container.select2-container--default.select2-container--below.select2-container--open {
	width: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100% !important;
}

.my-select .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 100%;
}